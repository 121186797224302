@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html, body {
    overscroll-behavior: contain;
}

@font-face {
    font-family: RNSSanz;
    src: url("./fonts/RNSSanz-Light.woff2");
    font-weight: 300;
}

@font-face {
    font-family: RNSSanz;
    src: url("./fonts/RNSSanz-Normal.woff2");
    font-weight: 400;
}

@font-face {
    font-family: RNSSanz;
    src: url("./fonts/RNSSanz-Medium.woff2");
    font-weight: 500;
}

@font-face {
    font-family: RNSSanz;
    src: url("./fonts/RNSSanz-SemiBold.woff2");
    font-weight: 600;
}

@font-face {
    font-family: RNSSanz;
    src: url("./fonts/RNSSanz-Bold.woff2");
    font-weight: 700;
}

@font-face {
    font-family: RNSSanz;
    src: url("./fonts/RNSSanz-ExtraBold.woff2");
    font-weight: 800;
}

@font-face {
    font-family: RNSSanz;
    src: url("./fonts/RNSSanz-Black.woff2");
    font-weight: 900;
}

@font-face {
    font-family: Montserrat;
    src: url("./fonts/Optimized-Montserrat-ExtraLight.ttf");
    font-weight: 200;
}

@font-face {
    font-family: Montserrat;
    src: url("./fonts/Optimized-Montserrat-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: Montserrat;
    src: url("./fonts/Optimized-Montserrat-Regular.ttf");
    font-weight: 400;
}

@keyframes slider {
    0% {
        transform: translateX(0);
    }

    33% {
        transform: translateX(-100vw);
    }

    66% {
        transform: translateX(-200vw);
    }

    100% {
        transform: translateX(0);
    }
}

@layer components {
    /* Loading interstitial */
    #loading-page {
        @apply animate-page-fade-out
    }

    #layout {
        @apply bg-black;
    }

    /* Header */
    #header {
        @apply justify-between px-2 py-4 lg:py-0 text-white shadow-xl z-40 absolute top-0 w-screen min-h-12 flex;
        background-color: rgba(22, 22, 23, 0.8);
        transition: min-height 0.5s, background-color 0.5s;

        &:hover, &.hamburgActive {
            @apply min-h-16;
            background-color: rgba(44, 44, 46, 0.8);

            & #navbar-logo-container {
                @apply max-h-16;
            }
        }

        /* Navbar */

        & #navbar {
            @apply flex items-center container px-4 mx-auto flex-wrap justify-between;

            & #navbar-logo-container {
                @apply w-full relative flex justify-between lg:w-auto px-4 lg:static lg:block lg:justify-start text-xl max-h-12;
                transition: max-height 0.5s;

                & #hamburgerbtn {
                    @apply flex lg:hidden text-base font-medium leading-snug text-gray-200 rounded-lg border-2 border-transparent hover:text-gray-100;
                }
            }

            & #navbar-menu-container {
                background-color: rgba(44, 44, 46, 0.8);

                @apply transform -translate-y-1/2 scale-y-0 lg:flex flex-grow absolute top-20 lg:top-0 left-0 w-full lg:translate-y-0 lg:scale-y-100 lg:relative lg:bg-transparent lg:w-auto;
                transition: transform 0.5s;

                & #navbar-menu-container-menu {
                    @apply lg:flex lg:flex-row list-none ml-auto;

                    & .navbar-menu-link {
                        @apply transform px-3 py-2 flex items-center text-base font-medium leading-snug text-gray-200 rounded-lg border-2 border-transparent hover:text-gray-100 hover:scale-110;
                        transition: color 0.5s, transform 0.5s;
                    }

                    & .nav-item-button-container {
                        @apply ml-0 lg:ml-8;

                        & .nav-item-button {
                            @apply px-3 m-2 py-2 justify-center flex items-center text-base font-normal leading-snug text-white bg-red-500 rounded-lg border-2 border-red-200 hover:bg-red-700;
                        }
                    }
                }
            }
        }

        &.hamburgActive {

            & #navbar {
                @apply flex-col items-center justify-center;
            }

            & #navbar-menu-container {
                @apply block translate-y-0 scale-y-100 !important;
            }

            & .navbar-menu-link {
                @apply hover:translate-x-8;
            }
        }
    }

    /* Parallax */
    #fullpage {

        @apply font-extralight;
        font-family: Montserrat, sans-serif;

        & .section {
            @apply relative;

            & .section-container {
                @apply container mx-auto h-full z-10 flex items-center pt-12;

                & .section-container-inner {
                    @apply relative absolute bg-gray-900 bg-opacity-80 rounded-xl text-white p-10;
                }
            }
        }
    }

    /* Footer */
    #footer {
        @apply p-4 bg-black w-full left-0;

        & #footer-container {
            @apply container mx-auto px-8;
        }

        & #footer-container-inner {
            @apply w-full flex flex-col md:flex-row py-6;
        }

        & .footer-section {
            @apply flex-1 mb-6 text-white text-sm;

            & .footer-section-segment-title {
                @apply uppercase font-semibold text-gray-200 mb-1;
            }

            & .footer-section-segment {
                @apply mt-2 block mr-2 md:block md:mr-0;
            }

            & .footer-section-li {
                @apply mt-2 inline-block mr-2 md:block md:mr-0;

                & .footer-section-li-link {
                    @apply no-underline text-gray-400 hover:text-pink-400 hover:underline;
                }
            }
        }
    }

    .skills {

        @apply w-full;

        & li {
            @apply w-full text-base sm:text-lg mt-2;
        }

        & .progress {
            @apply w-full relative h-4 block rounded-full;
            box-shadow: inset 0 10px 5px rgba(60,60,60,0.2);
        }
    }

    .input-group {

        & input {
            @apply h-10;
        }

        & textarea {
            @apply py-4;
        }

        & input, & textarea {
            @apply border border-gray-500 rounded bg-gray-100 text-gray-900 px-4;
        }
    }

    .btn {
        @apply h-10 border border-gray-800 rounded-lg bg-blue-500 px-8 hover:bg-blue-600;
        transition: background-color 0.1s;

        &:disabled {
            @apply bg-blue-400 !important;
        }
    }

    .main-header {
        opacity: 0;
        transition: opacity 1.5s;
    }

    .experience {
        &>div {
            transition: transform 0.3s, width 0.3s;
            transition-delay: 0s, 0.2s;
        }

        & .section-container-inner {
            @apply p-2 !important;
        }
    }

    .contact {
        & .section-container-inner {
            @apply p-4 sm:p-10 !important;
        }

        & .socialicons {
            @apply py-4 !important;
        }

        & .social {
            @apply p-8 sm:p-10 !important;
        }
    }

    .description {
        @apply transform scale-x-0 opacity-0 ml-0 overflow-hidden w-0;
        transition: transform 0.3s, margin-left 0.3s, width 0.3s, opacity 0.5s;
        transition-delay: inherit;
    }

    .open {
        & .work {
            @apply w-1/2;
        }

        & .description {
            @apply flex justify-center opacity-100 transform scale-x-100 w-44;
        }
    }

    .drag {
        border: 2px solid red;
    }

    .grecaptcha-badge {
        display: none !important;
    }
}

@layer utilities {
    .slider {
        @apply transform-gpu translate-x-0 !important;
    }

    .text-shadow-default {
        text-shadow: 0px 0px 1px rgb(0 0 0 / 20%), 0px 0px 1px rgb(1 0 5 / 10%);
    }
    .text-shadow-sm {
        text-shadow: 1px 1px 3px rgb(36 37 47 / 25%);
    }

    .text-shadow-md {
        text-shadow: 0px 1px 2px rgb(30 29 39 / 19%), 1px 2px 4px rgb(54 64 147 / 18%);
    }

    .text-shadow-lg {
        text-shadow: 3px 3px 6px rgb(0 0 0 / 26%), 0 0 5px rgb(15 3 86 / 22%);
    }

    .text-shadow-xl {
        text-shadow: 1px 1px 3px rgb(0 0 0 / 29%), 2px 4px 7px rgb(73 64 125 / 35%);
    }

    .text-shadow-none {
        text-shadow: none;
    }
}

@media(max-height: 800px) {
    html {
        font-size: 0.8rem;
    }
}
